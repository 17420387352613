<template>
	<div v-if="haveInfoShow">
		<div class="container">
			<div class="header" @click="goback"><img src="../assets/images/back.png" alt=""> 图书资源</div>
			<div class="blian22"></div>
		</div>
		<div style="background:#f7f7f7;height:0.25rem;display: none;"></div>
		<div class="container">
			<div class="unitsummary-nav">
				<span :class="tabIndex == 1 ? 'active':''" @click="changeTab(1)" v-if="showArr[0]">视频</span>
				<span :class="tabIndex == 2 ? 'active':''" @click="changeTab(2)" v-if="showArr[1]">音频</span>
				<span :class="tabIndex == 3 ? 'active':''" @click="changeTab(3)" v-if="showArr[2]">图文</span>
				<span :class="tabIndex == 4 ? 'active':''" @click="changeTab(4)" v-if="showArr[3]">课件</span>
				<span :class="tabIndex == 5 ? 'active':''" @click="changeTab(5)" v-if="showArr[4]">资料</span>
			</div>
			<div class="unitsummary-list">
				<div class="list flex_outside" v-for="(item,index) in evenNumbers" :key="index" @click="goVideo(item.resourceType,item.resourceId,item.resourcePath,item.resourceName,item.resourceId)">
					<div class="left">
						<img src="../assets/images/u1.png" alt="" v-if="tabIndex==1">
						<img src="../assets/images/u2.png" alt="" v-if="tabIndex==2">
						<img src="../assets/images/u3.png" alt="" v-if="tabIndex==3">
						<img src="../assets/images/u4.png" alt="" v-if="tabIndex==4">
						<img src="../assets/images/u5.png" alt="" v-if="tabIndex==5">
					</div>
					<div class="right flex_inside" :class="tabIndex != 1&&tabIndex != 2 ? 'lh': ''">
						<h3 class="ellipsis_one">{{item.resourceName}}</h3>
						<div class="us-bottom">
							<span v-if="tabIndex == 1 && item.resourceTime">
                              <img src="../assets/images/time.png" alt=""><em>{{item.resourceTime | formatDate}}</em> 
                            </span>
							<span v-if="tabIndex == 1 || tabIndex == 2">
                              <img src="../assets/images/memory.png" alt=""><em>{{item.resourceSize}}M</em> 
                            </span>
						</div>
					</div>
				</div>
				<div class="vertical" style="position: absolute;font-size: 0.7rem;" v-if="evenNumbers.length<=0">
					暂无内容...
				</div>
			</div>
		</div>
		<div class="goHome" v-if="isTwoCode" @click="goHome">
			<img src="../assets/images/goHmone2.png" />
		</div>
		<QrCode @hidden="hiddenShow" v-show="showQrCode" :codeurl="codeUrl"></QrCode>
	</div>
</template>
<script>
	import { listResource, qrcodeUrl, wxUserAuth, isSubscribe } from "@/assets/js/api";
	import QrCode from '@/components/QrCode.vue';
	import dateFormat from '@/assets/js/date'
	export default {
		name: "unitsummary",
		data() {
			return {
				isTwoCode: false, //是否是扫描二维码进入的
				code: '', //微信回调code
				appid: '', //微信回调appid
				redirectUrl: '', //当前页面地址
				token: '',
				showArr: [false, false, false, false, false],
				haveInfoShow: false,
				codeUrl: '',
				showQrCode: false,
				tabIndex: 2,
				unitsummaryArr: []
			};
		},
		components: {
			QrCode,
		},
		computed: {
			evenNumbers: function() {
				let that = this;
				return this.unitsummaryArr.filter(function(item) {
					return item.resourceType == that.tabIndex
				})
			},
		},
		filters: {
			formatDate(time) {
				return dateFormat.formatDate(time, "hh:mm:ss");
			}
		},
		methods: {
			goHome: function() {
				this.$router.replace({ //核心语句
					path: '/', //跳转的路径
				})
			},
			goback: function() {
				this.$router.go(-1)
			},
			changeTab: function(index) {
				this.tabIndex = index;
				if(index != this.$route.query.tab) {
					this.$router.replace({
						path: "/unitsummary",
						query: {
							tab: index,
							id: this.id,
						}
					});
				}
			},
			goVideo: function(resourceType, url, resourcePath, resourceName,resourceId ,haveOne) {
				let path = ''
				if(resourceType == 1) {
					path = './unitsummaryvideo'
				} else if(resourceType == 2) {
					path = './unitsummaryAudio'
				} else if(resourceType == 3) {
					path = './unitsummaryTu'
				} else if(resourceType == 4) {
					path = './unitsummaryPdf'
				} else if(resourceType == 5) {
					path = './unitsummaryZip'
				}
				if(haveOne == 1) {
					this.$router.replace({
						path: path,
						query: {
							resourceId:resourceId,
							chapId: this.id,
							url: url,
							path: resourcePath,
							titleName: resourceName
						}
					});
				} else {
					this.$router.push({
						path: path,
						query: {
							resourceId:resourceId,
							chapId: this.id,
							url: url,
							path: resourcePath,
							titleName: resourceName
						}
					});
				}
			},
			//获取单元信息
			getlistResource() {
				listResource({
					chapterId: this.id,
				}).then(res => {
					if(res.code == 0) {
						document.title = res.chapterInfo.chapterName;
						console.log(res.chapterInfo.topType)
						//判断是否用书链地址
						if(res.chapterInfo.topType == 2) {
							window.location.replace(res.chapterInfo.linkUrl);
//                          window.open(res.chapterInfo.linkUrl)
//							this.$router.replace({
//								path: './lookWai',
//								query: {
//									url: res.chapterInfo.linkUrl,
//								}
//							});
						} else {
							this.isEr();
							if(res.resourceList.length == 1 && !this.isTwoCode) {
								//if(res.resourceList.length == 1) {
								//如果只有一个资源 并且不是扫码进来的
								let goInfo = res.resourceList[0]
								this.goVideo(goInfo.resourceType, goInfo.resourceId,goInfo.resourcePath, goInfo.resourceName,goInfo.resourceId,1)
							} else {
								this.unitsummaryArr = res.resourceList;
								for(var i = 0; i < res.resourceList.length; i++) {
									if(res.resourceList[i].resourceType == 1) {
										this.showArr[0] = true;
									}
									if(res.resourceList[i].resourceType == 2) {
										this.showArr[1] = true;
									}
									if(res.resourceList[i].resourceType == 3) {
										this.showArr[2] = true;
									}
									if(res.resourceList[i].resourceType == 4) {
										this.showArr[3] = true;
									}
									if(res.resourceList[i].resourceType == 5) {
										this.showArr[4] = true;
									}
								}
								for(var i = 0; i < this.showArr.length; i++) {
									if(this.showArr[i] == true) {
										if(!this.$route.query.tab) {
											this.tabIndex = i + 1;
										} else {
											this.tabIndex = this.$route.query.tab;
										}
										break
									}
								}
								this.haveInfoShow = true;
							}
						}
					}
				});
			},
			hiddenShow: function() {
				this.showQrCode = false
			},
			//获取公众号二维码信息
			getQrcodeUrl() {
				qrcodeUrl({
					chapterId: this.id,
					type: 1
				}).then(res => {
					if(res.code == 0) {
						this.showQrCode = true; //二维码显示
						this.codeUrl = res.codeUrl;
					}
				});
			},
			//请求微信授权
			wxUserAuth() {
				wxUserAuth({
					redirectUrl: this.redirectUrl,
					chapterId: this.id,
				}).then(res => {
					if(res.code == 0) {
						window.location.href = res.url
					}
				});
			},
			//判断是否关注过微信号
			isSubscribe() {
				isSubscribe({
					appid: this.appid,
					code: this.code,
				}).then(res => {
					if(res.code == 0) {
						if(!res.isSubcribe) {
							this.getQrcodeUrl(); //没有关注过拉取关注二维码信息
						}
						sessionStorage.setItem('isSubcribe', 1)
					}
				});
			},
			isEr() {
				if(this.$route.query.id) {
					//正常进入
					this.id = this.$route.query.id;
				} else {
					//扫描二维码进入
					this.id = this.$route.query.chapterId;
					this.isTwoCode = true; //是否是扫描二维码进入的 控制回首页的按钮
					if(!this.$route.query.token) {
						//没有token请求微信登录
						if(this.$route.query.code) {
							//有code说明是微信回调
							this.code = this.$route.query.code;
							this.appid = this.$route.query.appid;
							if(!sessionStorage.getItem('isSubcribe')) {
								this.isSubscribe()
							}
						} else {
							this.redirectUrl = window.location.href; //获取当前地址
							this.wxUserAuth(); //请求微信登录授权
						}
					} else {
						//从公众号进入有token
						this.token = this.$route.query.token;
						localStorage.setItem('token', this.$route.query.token); //保存登陆token
					}
				}
			}
		},
		created() {
			//设置tab选中项
			if(!this.$route.query.tab) {
				this.tabIndex = 1;
			} else {
				this.tabIndex = this.$route.query.tab;
			};
			if(this.$route.query.id) {
				//正常进入
				this.id = this.$route.query.id;
			} else {
				//扫描二维码进入
				this.id = this.$route.query.chapterId;
				this.isTwoCode = true; //是否是扫描二维码进入的 控制回首页的按钮
			}

//			if(this.$route.query.id) {
//				//正常进入
//				this.id = this.$route.query.id;
//			} else {
//				//扫描二维码进入
//				this.id = this.$route.query.chapterId;
//				this.isTwoCode = true; //是否是扫描二维码进入的 控制回首页的按钮
//				if(!this.$route.query.token) {
//					//没有token请求微信登录
//					if(this.$route.query.code) {
//						//有code说明是微信回调
//						this.code = this.$route.query.code;
//						this.appid = this.$route.query.appid;
//						if(!sessionStorage.getItem('isSubcribe')) {
//							this.isSubscribe()
//						}
//					} else {
//						this.redirectUrl = window.location.href; //获取当前地址
//						this.wxUserAuth(); //请求微信登录授权
//					}
//				} else {
//					//从公众号进入有token
//					this.token = this.$route.query.token;
//					localStorage.setItem('token', this.$route.query.token); //保存登陆token
//				}
//			}
			//处理二维码扫描进入
			this.getlistResource();
		},
		//		beforeDestroy: function () {
		//          localStorage.setItem('isSubcribe','');
		//      },
		//      destroyed: function () {
		//          localStorage.setItem('isSubcribe','');
		//      }
	};
</script>
<style>
	.goHome {
		width: 2rem;
		height: 2rem;
		position: fixed;
		right: 0.5rem;
		bottom: 2rem;
		box-shadow: 0 0 6px rgba(0, 0, 0, .12);
		border-radius: 50%;
		background-color: #FFFFFF;
	}
	
	.goHome img {
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		margin-top: 0.25rem;
		margin-left: 0.25rem;
	}
</style>